<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <!-- FORM START -->
      <div class="vx-row mb-5">
        <div class="vx-col w-1/2">
          <vs-input class="w-full" v-validate="'required'" name="code" label="Code" v-model="code"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('code')">{{errors.first('code')}}</span>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <vs-input class="w-full" v-validate="'required'" name="name" label="Route Name" v-model="name"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('name')">{{errors.first('name')}}</span>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <browse-cities class="w-full" v-validate="'required'" name="city_start_id" label="City Start" v-model="city_start_id"></browse-cities>
          <span class="text-danger text-sm" v-show="errors.has('city_start_id')">{{errors.first('city_start_id')}}</span>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <browse-cities class="w-full" v-validate="'required'" name="city_end_id" label="City Finish" v-model="city_end_id"></browse-cities>
          <span class="text-danger text-sm" v-show="errors.has('city_end_id')">{{errors.first('city_end_id')}}</span>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-1/3">
          <vs-input class="w-full" type="number" @focus="$event.target.select()" v-validate="'required'" name="distance" label="Distance (km)" v-model="distance"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('distance')">{{errors.first('distance')}}</span>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-1/3">
          <vs-input class="w-full" type="number" @focus="$event.target.select()" v-validate="'required'" name="time" label="Estimated Time (hours)" v-model="time"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('time')">{{errors.first('time')}}</span>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-1/3">
          <vs-input class="w-full" type="number" @focus="$event.target.select()" v-validate="'required'" name="point" label="Point" v-model="point"></vs-input>
          <span class="text-danger text-sm" v-show="errors.has('point')">{{errors.first('point')}}</span>
        </div>
      </div>
      <div class="vx-row mb-5">
        <div class="vx-col w-full">
          <vs-input class="w-full" label="Description" v-model="description"></vs-input>
        </div>
      </div>
      <!-- FORM END -->
    </div>
    <div class="vx-col w-full flex">
      <vs-button @click="store" color="primary" icon-pack="feather" icon="icon-save">Save</vs-button>&nbsp;
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import BrowseCities from '@browse/Cities.vue'

export default {
  components:{
    BrowseCities
  },
  props:{
    id: {
      type: String,
      default: null
    }
  },
  data(){
    return {
      code: "",
      name: "",
      city_start_id: null,
      city_end_id: null,
      distance: 0,
      time: 0,
      point: 0,
      description: null,
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'routes/store',
      dispatchUpdate: 'routes/update',
      dispatchShow: 'routes/show'
    }),
    store(){
      this.$validator.validateAll().then(async res => {
        if(!res) return false;
        let payload = {
          id: this.id,
          code: this.code,
          name: this.name,
          city_start_id: this.city_start_id,
          city_end_id: this.city_end_id,
          distance: this.distance,
          time: this.time,
          point: this.point,
          description: this.description,
        }
        try {
          if (this.id) {
            let {data} = await this.dispatchUpdate(payload)
            this.$emit('on-success', data)            
          } else {
            let {data} = await this.dispatchStore(payload)
            this.$emit('on-success', data)
          }
        } catch (error) {
          this.$vs.notify({
            title: 'Oops!',
            text: error.data.message,
            color: 'danger'
          })
        }
      })
    },
    async getDetail(){
      let { data } = await this.dispatchShow(this.id)
      this.code = data.code
      this.name = data.name
      this.city_start_id = data.city_start_id
      this.city_end_id = data.city_end_id
      this.distance = data.distance
      this.time = data.time
      this.point = data.point
      this.description = data.description
    }
  },
  mounted(){
    if (this.id) {
      this.getDetail()
    }
  }
}
</script>

<style>

</style>